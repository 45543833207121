import React, { Component } from "react";
import ReactDOM from "react-dom";
import '@fortawesome/fontawesome-free/css/all.min.css';
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import { MDBBtn, MDBInput, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBIcon, MDBBadge, MDBContainer, MDBRow, MDBCol } from "mdbreact";


class Event extends Component {

    render() {
        return (
            <React.Fragment>
                <div className="media mt-1">
                    <h3 className="h3-responsive font-weight-bold mr-3">
                        {this.props.time}
                    </h3>
                    <div className="media-body mb-3 mb-lg-3">
                        <MDBBadge
                            color="danger"
                            className="ml-2 float-right"
                            onClick={() => this.props.delete(this.props.id)}>
                            -
                        </MDBBadge>
                        <h4 className="mt-0 font-weight-bold">{this.props.title} </h4>{" "}
                        <hr className="hr-bold my-2" />
                        {this.props.location && (
                            <React.Fragment>
                                <p className="font-smaller mb-0">
                                    <MDBIcon icon="location-arrow" /> {this.props.location}
                                </p>
                            </React.Fragment>
                        )}
                    </div>
                </div>
                {this.props.description && (
                    <p className="p-2 mb-4  blue-grey lighten-5 blue-grey lighten-5">
                        {this.props.description}
                    </p>
                )}
            </React.Fragment>
        );
    }
}

class Leson3 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            newTask: {},
            events: [
                {
                    id: 1,
                    location: 'tita',
                    description: 'opisanie',
                    title: 'odin',
                    time: '3:12'
                },
                {
                    id: 2,
                    description: 'opisanie2',
                    title: 'dva',
                    time: '8:42'
                },
                {
                    id: 3,
                    location: 'tita3',
                    description: 'opisanie3',
                    title: 'tri',
                    time: '13:12'
                },
                {
                    id: 4,
                    location: 'aaaaaaaaaa',
                    title: 'aga aaaaaa',
                    time: '13:12'
                },
            ]
        };
    };

    toggleModal = () => {
        this.setState({
            modal: !this.state.modal
        });
    };

    createTask = (ipName) => (val) => {
        // const thisTask = this.state.newTask;
        // thisTask[ipName] = val;
        this.setState({ [ipName]: val })
        // console.log(this.state)
    }
    writeTask = () => {
        var newArray = [...this.state.events];
        newArray.push({
            id: newArray.length ? newArray[newArray.length - 1].id + 1 : 1,
            time: this.state.time,
            title: this.state.title,
            location: this.state.location,
            description: this.state.description,
            value: this.var > 5 ? "Its's grater then 5" : "Its lower or equal 5"
        });
        this.setState({ events: newArray });
        this.setState({
            time: "",
            title: "",
            location: "",
            description: ""
        });
    };


    loopDeal = () => {
        return (
            this.state.events.map(event => {
                //event.title = event.title + '  aga aga';
                return (<Event
                    id={event.id}
                    key={event.id}
                    description={event.description}
                    location={event.location}
                    title={event.title}
                    time={event.time}
                    delete={this.delete}
                />
                );

            }))
    };

    delete = (id) => {
        //  alert(id)
        const oldState = this.state.events.filter(e => e.id !== id);
        this.setState({ events: oldState })
    }

    render() {
        return (
            <React.Fragment>
                <MDBContainer>
                    <MDBRow>
                        <MDBCol md="9">
                            <h2 className="text-uppercase my-3">Сегодня</h2>
                            <div id="schedule-items">
                                {this.loopDeal()}
                            </div>
                            <MDBRow className="mb-4">
                                <MDBCol xl="3" md="6" className="mx-auto text-center">
                                    <MDBBtn color="info" rounded onClick={this.toggleModal}>
                                        Добавить Задачу
                  </MDBBtn>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>

                        <MDBCol md="3" >
                            <h3 className="text-uppercase my-3">Список дел</h3>
                            <h6 className="my-3">
                                У тебя  {" "}
                                <b>{this.state.events.length} дел </b> сегодня.</h6>
                            <h1 className="my-3">
                                <MDBRow>
                                    <MDBCol xs="3" className="text-center">
                                        <MDBIcon icon="sun" fixed />
                                    </MDBCol>
                                    <MDBCol xs="9">Sunny</MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol xs="3" className="text-center">
                                        <MDBIcon icon="thermometer-three-quarters" fixed />
                                    </MDBCol>
                                    <MDBCol xs="9">23°C</MDBCol>
                                </MDBRow>
                            </h1>
                            <p>
                                Все ОК )
              </p>
                        </MDBCol>
                    </MDBRow>
                    <MDBModal isOpen={this.state.modal} toggle={this.toggleModal}>
                        <MDBModalHeader
                            className="text-center"
                            titleClass="w-100 font-weight-bold"
                            toggle={this.toggleModal}
                        >
                            Add new event
          </MDBModalHeader>
                        <MDBModalBody>
                            <form className="mx-3 grey-text">
                                <MDBInput
                                    name="time"
                                    label="Time"
                                    icon="clock"
                                    hint="12:30"
                                    group
                                    type="text"
                                    getValue={this.createTask("time")}
                                />
                                <MDBInput
                                    name="title"
                                    label="Title"
                                    icon="edit"
                                    hint="Briefing"
                                    group
                                    type="text"
                                    getValue={this.createTask("title")}
                                />
                                <MDBInput
                                    name="location"
                                    label="Location (optional)"
                                    icon="map"
                                    group
                                    type="text"
                                    getValue={this.createTask("location")}
                                />
                                <MDBInput
                                    name="description"
                                    label="Description (optional)"
                                    icon="sticky-note"
                                    group
                                    type="textarea"
                                    getValue={this.createTask("description")}
                                />
                            </form>

                        </MDBModalBody>
                        <MDBModalFooter className="justify-content-center">
                            <MDBBtn
                                color="info"
                                onClick={() => {
                                    this.toggleModal();
                                    this.writeTask();
                                }}
                            >
                                Добавить
    </MDBBtn>
                        </MDBModalFooter>
                    </MDBModal>
                </MDBContainer>
            </React.Fragment >

        );
    }
}
export default Leson3