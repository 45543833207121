import React, { Component } from 'react';
import { Button } from "mdbreact";
import logo from './logo.png';
import Leson4 from './apps/Leson4'

class App extends Component {
  render() {
    return (
      // <div className="App">
      <div>
        <Leson4 />
      </div>
    );
  }
}

export default App;